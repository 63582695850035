import axios from "axios";
import React, { useState } from "react";

const Test = () => {
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [backend, setBackend] = React.useState([]);
  const [backerror, setBackerror] = useState(false);

  // const handleClick = (e) => {
  //   setBackerror(false);
  //   axios
  //     .post(`${process.env.REACT_APP_Backend_API}/api/contact/`, {
  //       email,
  //       message,
  //     })
  //     .then((res) => {
  //       console.log("this is data", res.data.errors);
  //       if (res.data.errors) {
  //         setBackend(res.data.errors);
  //         setBackerror(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(setBackerror(false));
  // };

  const handleClick = async (e) => {
    setSubmitting(true);
    await axios
      .post(
        `${process.env.REACT_APP_Backend_API}/api/contact/`,
        {
          email,
          message,
        },
        {
          "Content-Type": "multipart/form-data",
        }
      )
      .then((res) => {
        console.log("this is data", res.data.errors);
        if (res.data.errors) {
          setBackend(res.data.errors);
          setBackerror(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setSubmitting(false), setBackerror(false));
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gray-50">
      <div>
        <div>
          {/* {console.log("this is backend", backerror ? backend[0].msg : null)} */}
          <div>
            {submitting
              ? backend.map((b, index) => (
                  <div key={index} className="text-red-400">
                    {" "}
                    {b.msg}{" "}
                  </div>
                ))
              : null}
          </div>

          <div>
            <input
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter your Email"
              value={email}
              className="block"
            />
            {console.log(backerror)}

            {backerror ? backend[0].msg : null}

            <textarea
              name="message"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              value={message}
              placeholder="Enter your Message"
              className="block my-5"
            />

            <div className="text-red-400"> {backerror & backend} </div>
          </div>
          <button
            onClick={handleClick}
            type="submit"
            className=" w-[80px] pt-1 opacity-90 hover:opacity-100 transition-opacity mt-[20px] py-[4px] px-1 h-[32px] rounded-[8px] text-white bg-[#005462] "
          >
            submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Test;
