/** @format */

import React from "react";
import about from "assets/About us/about.png";

function Header() {
  return (
    <div className='bg-colorWhite dark:bg-darkHeader w-full h-[300px] '>
      {/* headeer */}
      <div className='flex px-8  max-w-screen-xl h-full relative mx-auto lg:grid lg:grid-cols-10'>
        <div className='col-span-5 flex flex-col justify-center text-left '>
          <h1 className='text-grayPrimary mt-6 uppercase text-[35px] font-bold'>
            About Us
          </h1>
          <h1 className='uppercase text-textPrimary text-[40px] font-bold mt-6 '></h1>
        </div>
        <div className=' hidden lg:block justify-center lg:justify-end  lg:col-span-5'>
          <div className='h-[170px] lg:absolute lg:top-32 lg:right-32 '>
            <img src={about} className='w-full  h-full ' alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
